'use client';
import { Button, buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import { ChevronLeftIcon, HomeIcon } from '@heroicons/react/16/solid';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

export default function NotFound() {
  const { back } = useRouter();
  return (
    <div className='flex h-screen flex-col items-center justify-center gap-12'>
      <div className='flex gap-12'>
        <div className='text-8xl'>404</div>
        <div className='text-xl font-medium'>Sidan hittades inte</div>
      </div>

      <div className='flex items-center gap-12'>
        <Button variant='secondary' onClick={() => back()}>
          <ChevronLeftIcon className='mr-1 size-4' /> Gå tillbaka
        </Button>

        <Link
          className={cn(buttonVariants({ variant: 'outline' }))}
          href={Urls.dashboard.home}
        >
          Gå till startsidan <HomeIcon className='ml-1 size-4' />
        </Link>
      </div>
    </div>
  );
}
